<template>
  <v-select
    v-model="selected"
    :items="localProducts"
    item-value="value"
    hide-no-data
    :label="$t('labels.product')"
    required
    :readonly="readonly"
    :error-messages="errors"
    :menu-props="{ maxWidth: '316px', closeOnContentClick: true }"
  >
    <template v-slot:selection="{ item }">
      <v-list-item-content>{{ $t(`labels.${item.value}`) }}</v-list-item-content>
    </template>

    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>{{ $t(`labels.${item.value}`) }}</v-list-item-title>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { NAMESPACE } from '../store'
export default {
  name: 'ProductField',
  props: {
    updateSelection: Function,
    provided: Array,
    selection: String,
    errors: Array,
    required: Boolean,
    readonly: Boolean,
    dealId: { type: [Number, String], required: true }
  },
  methods: {
    ...mapActions(NAMESPACE, ['listProducts'])
  },
  computed: {
    ...mapState(NAMESPACE, ['products']),
    selected: {
      get() {
        return this.selection
      },
      set(value) {
        this.updateSelection(value)
      }
    },
    localProducts() {
      return this.provided || this.products
    }
  },
  created() {
    let shouldGet = !this.provided
    if (shouldGet) {
      this.listProducts(this.dealId).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
>>> .v-list__tile {
  height: 72px !important;
  align-items: top !important;
}

.v-list__tile__sub-title {
  white-space: normal !important;
}
</style>
