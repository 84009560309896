<template>
  <VModalWidget
    :submit="submit"
    :dismiss="dismiss"
    :loading="loading"
    :submitting="submitting"
    :submit-title="buttonTitle"
  >
    <template v-slot:title>{{ $t('labels.product') }}</template>

    <template v-slot:content>
      <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
      <v-form>
        <v-container grid-list-md class="px-0 py-0">
          <v-row>
            <v-col cols="12"></v-col>
            <v-col cols="12">
              <product-field
                :deal-id="entityId"
                :selection="form.type"
                :update-selection="
                  (v) => {
                    form.type = v
                  }
                "
                :readonly="!isNew"
              />
            </v-col>
            <v-col cols="6" v-if="!isNew">
              <v-text-field :value="$t(`labels.${form.category}`)" :label="$t('labels.category')" readonly />
            </v-col>
            <v-col cols="6" v-if="!isNew">
              <CurrencyField v-model="form.monthly_fee" readonly :label="$t('labels.monthlyFee')" clearable />
            </v-col>
            <template v-if="showDates">
              <v-col cols="6">
                <DateField
                  v-model="form.meta.dates.acceptance_date"
                  :timezone="timezone"
                  :label="$t('labels.acceptanceDate')"
                  clearable
                />
              </v-col>
              <v-col cols="6">
                <DateField
                  v-model="form.meta.dates.premiums_start_date"
                  :timezone="timezone"
                  :label="$t('labels.premiumsStartDate')"
                  clearable
                />
              </v-col>
              <v-col cols="12" v-if="showSold">
                <DateField
                  v-model="form.meta.dates.sold_on"
                  :timezone="timezone"
                  :label="$t('labels.soldOn')"
                  clearable
                />
              </v-col>
            </template>
            <template v-if="showStatusDates">
              <v-col cols="6">
                <DateField
                  v-model="form.meta.dates.ongoing_bought_on"
                  :timezone="timezone"
                  :label="$t('labels.ongoingBoughtOn')"
                  clearable
                />
              </v-col>
              <v-col cols="6">
                <DateField
                  v-model="form.meta.dates.finalized_delivered_on"
                  :timezone="timezone"
                  :label="$t('labels.finalizedDeliveredOn')"
                  clearable
                />
              </v-col>
            </template>
            <v-col v-if="form.type === 'discount'">
              <v-row>
                <v-col cols="4">
                  <v-checkbox v-model="form.meta.discount.use_percentage" :label="$t('unit')" />
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="form.meta.discount.amount"
                    :label="$t('labels.amount')"
                    :suffix="form.meta.discount.use_percentage ? '%' : null"
                    :error-messages="discountErrors"
                    @input="$v.form.meta.discount.amount.$touch()"
                    @blur="$v.form.meta.discount.amount.$touch()"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </VModalWidget>
</template>

<script>
import pick from 'lodash/pick'
import { mapActions, mapState, mapGetters } from 'vuex'
import { decimal } from 'vuelidate/lib/validators'
import { VModalWidget, BaseForm, FormErrors, DateField } from '@argon/app/components'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { NAMESPACE as ENTITY_NAMESPACE } from '@argon/evo/entities/store'
import { Modal } from '@argon/app/mixins'
import { NAMESPACE } from '../store'
import ProductField from './ProductField'
import { CurrencyField } from '../../components'
const DISCOUNT = 'discount'

export default {
  name: 'ProductModal',
  extends: BaseForm,
  mixins: [Modal],
  components: {
    VModalWidget,
    FormErrors,
    ProductField,
    DateField,
    CurrencyField
  },
  props: {
    entityId: { type: [String, Number], required: true },
    policyId: { type: [String, Number], required: true },
    productId: { type: [String, Number], required: true }
  },
  validations: {
    form: {
      meta: {
        dates: {
          acceptance_date: {},
          premiums_start_date: {},
          sold_on: {},
          ongoing_bought_on: {},
          finalized_delivered_on: {}
        },
        discount: {
          amount: { decimal }
        }
      }
    }
  },
  data: () => ({
    form: {
      type: '',
      meta: {
        dates: {
          acceptance_date: '',
          premiums_start_date: '',
          ongoing_bought_on: '',
          finalized_delivered_on: '',
          sold_on: ''
        },
        discount: {
          use_percentage: false,
          amount: ''
        }
      }
    },
    loading: true,
    submitting: false
  }),
  computed: {
    ...mapGetters(ME_NAMESPACE, ['timezone']),
    ...mapState(ENTITY_NAMESPACE, ['boardItem']),
    showStatusDates() {
      return (
        ['bought', 'delivered'].includes(this.boardItem.step) &&
        ['finalized', 'ongoing'].includes(this.boardItem.entity.status)
      )
    },
    showDates() {
      return ['life', 'life_smoker', 'disability'].includes(this.form.type)
    },
    showSold() {
      return ['bought', 'approved', 'delivered', 'paid'].includes(this.boardItem.step)
    },
    isNew() {
      return this.productId === 'new'
    },
    buttonTitle() {
      return this.isNew ? this.$t('labels.create') : this.$t('labels.save')
    },
    discountErrors() {
      const errors = []
      if (!this.$v.form.meta.discount.amount.$dirty) return errors
      !this.$v.form.meta.discount.amount.decimal && errors.push(this.$t('labels.invalid'))
      this.getServerErrors('amount', errors)
      return errors
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['createProduct', 'patchProduct', 'getProduct', 'getDefaultDates']),
    submit() {
      if (this.$v.form.$invalid) {
        return this.$v.form.$touch()
      }
      this.submitting = true
      if (this.form.type === DISCOUNT) {
        delete this.form.meta['dates']
      } else {
        delete this.form.meta['discount']
      }
      let data = this.isNew ? pick(this.form, ['type', 'meta']) : pick(this.form, ['meta'])

      let payload = this.isNew
        ? { dealId: this.entityId, policyId: this.policyId, data }
        : {
            dealId: this.entityId,
            policyId: this.policyId,
            productId: this.productId,
            data
          }
      const callback = this.isNew ? this.createProduct : this.patchProduct
      callback(payload)
        .then(() => {
          this.dismiss()
        })
        .catch(this.setServerErrors)
        .finally(() => {
          this.submitting = false
        })
    }
  },
  created() {
    if (this.isNew) {
      this.getDefaultDates({
        dealId: this.entityId,
        policyId: this.policyId
      }).then((res) => {
        this.form.meta.dates = res.coverage
        this.loading = false
      })
    } else {
      this.getProduct({
        dealId: this.entityId,
        policyId: this.policyId,
        productId: this.productId
      }).then((res) => {
        this.form = res
        this.loading = false
      })
    }
  }
}
</script>

<i18n>
{
  "en": {
    "type": "@:labels.type",
    "unit": "Use percentage",
    "total": "@:labels.total"
  },
  "fr": {
    "type": "@:labels.type",
    "unit": "Utiliser un pourcentage",
    "total": "@:labels.total"
  }
}
</i18n>
